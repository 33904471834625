import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest
} from '@angular/common/http';
import { inject } from '@angular/core';
import { createContextLogger } from '@konnektu/helpers';
import { catchError, switchMap, take, throwError } from 'rxjs';
import { AuthService } from './auth.service';

export const authFuncInterceptor: HttpInterceptorFn = (req, next) => {
  const logger = createContextLogger('authFuncInterceptor');

  const addTokenHeader = (request: HttpRequest<unknown>, token: string) =>
    request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });

  return inject(AuthService).accessToken$.pipe(
    take(1),
    switchMap((accessToken) =>
      next(accessToken ? addTokenHeader(req, accessToken) : req).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            logger.error(
              `Request failed, because current user is unauthorized. Redirecting to login page.`
            );
            return throwError(() => err);
          }
          logger.error('Request in interceptor failed', err);
          return throwError(() => err);
        })
      )
    )
  );
};
